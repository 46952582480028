import React from "react"
export const preventOrphans = text => {
  if (text && typeof text === "string") {
    const tokens = text.split(" ")
    if (tokens.length < 3) return text
    const two = tokens.pop()
    const last = tokens.pop()
    return (
      <>
        {tokens.join(" ")} {last}&nbsp;{two}
      </>
    )
  } else {
    return text
  }
}
