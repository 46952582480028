import React from "react"
import { preventOrphans } from "./utils"

const Headline = ({ children, sub = false, style }) => {
  const headline = preventOrphans(children)
  if (!sub) {
    return <h1 {...style}>{headline}</h1>
  } else {
    return <h2 {...style}>{headline}</h2>
  }
}

export default Headline
