import React from "react"
import Layout from "./Layout"
import styled from "styled-components"

import Headline from "./Headline"
import SEO from "./seo"

const PostContainer = styled.article`
  img + em,
  .gatsby-resp-image-wrapper + em {
    font-style: italic;
    margin-bottom: ${p => p.theme.margin * 2}px;
    margin-top: ${p => p.theme.margin}px;
    display: block;
    text-align: center;
  }
  p + img {
    width: 100%;
  }
`

const PostFooter = styled.footer`
  padding-top: ${p => p.theme.padding * 2}px;
  border-top: 1px solid ${p => p.theme.colors.border};
  margin-top: ${p => p.theme.margin}px;
`

const BlogLayout = ({ children, ...props }) => {
  const {
    frontmatter: { title, subtitle },
  } = props.pageContext
  console.log(props.pageContext)
  return (
    <Layout {...props}>
      <SEO title={title} />
      <header>
        <Headline>{title}</Headline>
        {subtitle ? <Headline sub>{subtitle}</Headline> : null}
      </header>
      <PostContainer>{children}</PostContainer>
      {/* <PostFooter>
        <Commento id={props.path} />
      </PostFooter> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        draft
        image {
          childImageSharp {
            fluid {
              aspectRatio
              src
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
      }
      body
    }
  }
`

export default BlogLayout
